<template lang="html">
  <LegacyLayout>
    <TileList>
      <ModuleTile
        v-for="report in workspace.reports" :key="report.id"
        @click="$router.push({name: report.type, params: {reportId: report.id}})"
      >
        {{ report.name }}
      </ModuleTile>
    </TileList>
  </LegacyLayout>
</template>

<script>
import {mapState} from 'vuex'

import LegacyLayout from '@components/nav/tiles/LegacyLayout'
import TileList from '@components/nav/tiles/TileList'
import ModuleTile from '@components/nav/tiles/ModuleTile'

export default {
  components: {
    LegacyLayout,
    TileList,
    ModuleTile
  },
  props: {
    workspaceId: String
  },
  computed: {
    ...mapState('powerBi', [
      'workspaces'
    ]),
    workspace () {
      return this.workspaces.find(x => x.id === this.workspaceId)
    }
  }
}
</script>

<style lang="scss" module>

</style>
