<template lang="html">
  <TileBase v-bind="$attrs" v-on="$listeners">
    <div :class="$style.text">
      <slot />
    </div>

    <template #image>
      <slot name="image" />
    </template>
  </TileBase>
</template>

<script>
import TileBase from './base/TileBase'

export default {
  components: {
    TileBase
  }
}
</script>

<style lang="scss" module>
.text {
  font-size: 1.25em;

  display: flex;
  align-items: flex-start;
}
</style>
